html, body, #root, .app {
  height: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
#root {
  width: 100vw;
  /* height: 100vh; */
  overflow: hidden;
}

#control-height {
    height: 100vh;
    width: 0;
    position: absolute;
}

.app {
  width: 100vw;
  /* height: 100vh; */

  display: flex;
  overflow: hidden;
}

.full-width {
  width: 100vw;
  font-size: 0.55vw;
}

.full-height {
  height: 100vh;
  font-size: 1vh;
}

.portrait.full-width {
  font-size: 1.55vw;
}

.landscape {
  position: relative;
  flex: 1 0 auto;

  background-image: url('image/bg-horz.svg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.landscape .waiting {
  position: absolute;
}


.landscape.full-width .waiting {
  top: 22%;
  right: 4.9vw;
  width: 41.3vw;
  height: 64%;
}

.landscape.full-height .waiting {
    top: 22vh;
    right: 5%;
    width: 41%;
    height: 64vh;
}


.portrait {
  position: relative;
  flex: 1 0 auto;
  background-image: url('image/bg-vert.svg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.portrait .waiting {
  position: absolute;
}

.portrait.full-width .waiting {
  top: 19%;
  left: 8vw;
  width: 84vw;
  height: 39%;
}

.portrait.full-height .waiting {
  top: 18vh;
  left: 6.5%;
  width: 87%;
  height: 40vh;
}

.promotion {
  position: absolute;
}

.promotion-message-title {
  font-weight: bold;
  text-align: start;
}

.promotion-message-text {
  text-align: start;
}

.portrait .promotion {
  top: 60%;
  right: 5%;
  width: 45%;
}

.portrait .promotion-message-text {
  font-size: 3.5vw;
}

.landscape .promotion {
  top: 30%;
  left: 24%;
  width: 30%;
}

.landscape .promotion-message-title {
  font-size: 5.55em;
  line-height: 1.3em;
}
.landscape .promotion-message-text {
  font-size: 3.55em;
  line-height: 1.3em;
}

.portrait .promotion-message-title {
  font-size: 3.55em;
  line-height: 1.3em;
  margin-bottom: 0;
}
.portrait .promotion-message-text {
  font-size: 2.27em;
  line-height: 1.3em;
}

.waiting .wrapper{
  position: relative;
  width: 100%;
  height: 100%;

  padding: 0 1vw;
  overflow-y: hidden;
}


.waiting .order>p.name {
  width: 25%;
}
/* .waiting .order>p.status { */
/*   width: 25%; */
/* } */
.waiting .order>p.product {
  width: 60%;
}
.waiting .order>p.est {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 8em;
  width: 15%;
}

.waiting .order>p.est>.robot-icon {
  margin-right: .5em;
}

.waiting .order {
  color: #6d6e71;
}

.waiting .waiting-list-title {
  color: #6d6e71;
}
.waiting .waiting-list-title .robot-name {
  text-transform: capitalize;
}

.waiting .robot-order-items .order {
  color: #000;
}

.waiting .order> .product {
  display: flex;
}
.waiting .order> .product > .status-icon {
  width: 1vw;
  margin-right: 0.2vw;
}
.waiting .order.done> .product > .status-icon {
  background: url('./image/icon-done.png') center center no-repeat;
  background-size: contain;
}
.waiting .order.preparing> .product > .status-icon {
  background: url('./image/icon-preparing.png') center center no-repeat;
  background-size: contain;
}

.portrait .waiting .order> .product > .status-icon {
  width: 2vw;
}


.robot-info {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
}

.robot-name {
  font-weight: bold;
  text-transform: uppercase;
}
.robot p {
  font-weight: bold;
}
.icon {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  margin-bottom: 0.2em;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.icon-robot {
  background-image: url('./image/robot.png');
  background-size: contain;
}
.icon-manual {
  background-image: url('./image/human.png');
  background-size: contain;
}
.icon-waiting {
  background-image: url('./image/icon-waiting.png');
  background-size: contain;
}

.order-items {
  margin-top: 1vh;
}

.landscape .robot-info {
  font-size: 3em;
  line-height: 2.2em;
}
.portrait .robot-info {
  font-size: 2em;
  line-height: 2.2em;
}

.waiting .order {
  display: flex;
}


@keyframes landscape-scroll {
    0%, 10%, 100%{
        transform: translateY(0);
    }
    92%, 98%{
        transform: translateY(calc(-100% + 64.62em));
    }
}

@keyframes portrait-scroll {
    0%, 10%, 100%{
        transform: translateY(0);
    }
    90%, 98%{
        transform: translateY(calc(-100% + 41em));
    }
}

.waiting .wrapper .content {
  padding: 0 0.5em;
  overflow-y: hidden;
}
.waiting .wrapper .content-scroll {
  /* animation-name: scrollll; */
  /* animation-duration: 3s; */
  animation-iteration-count: infinite;
}

.waiting .order>p {
  padding: 0;
  margin: 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: start;
}

.order.underline {
  border-bottom: 1px solid #000;
}

.waiting p.right {
  text-align: end;
}

.landscape .waiting .title>p {
  font-size: 3em;
  line-height: 2.2em;
  margin-top: 0.75vh;
}

.landscape .waiting .order>p {
  font-size: 2.6em;
  line-height: 2.2em;
}

.landscape .waiting-total {
  position: absolute;
  top: 5.5%;
  right: 5%;

  font-size: 6.1em;
  font-weight: bold;
}

.portrait .waiting .title>p {
  font-size: 2em;
  line-height: 2.2em;
}

.portrait .waiting .order>p {
  font-size: 1.7em;
  line-height: 2.2em;
}

.portrait .waiting-total {
  position: absolute;
  top: 8%;
  right: 10%;

  font-size: 3.7em;
  font-weight: bold;
}

p.cap {
  text-transform: capitalize;
}

/* stretch lines to the margin end */

.landscape.full-height:before {
  display: block;
  content: '';
  position: absolute;
  bottom: 7.63%;
  right: 100%;
  width: 50vw;
  height: 0.23vh;
  background-color: #000;
}

.landscape.full-height:after {
  display: block;
  content: '';
  position: absolute;
  bottom: 11.45%;
  left: 99.9%;
  width: 50vw;
  height: 0.23vh;
  background-color: #000;
}

.landscape .blue-circle {
  position: absolute;
  bottom: -5em;
  left: -7em;
  width: 42em;
  height: 42em;
  border-radius: 100%;
  background-color: #74B4E2;
  opacity: 0.3;
}

.portrait .pink-circle {
  position: absolute;
  bottom: 12em;
  right: -5em;
  width: 28em;
  height: 28em;
  border-radius: 100%;
  background-color: #EA8180;
  opacity: 0.4;
}

.portrait.full-height:before {
  display: block;
  content: '';
  position: absolute;
  top: 14.64%;
  right: 99.8%;
  width: 50vw;
  height: 0.15vh;
  background-color: #000;
}

.portrait.full-height:after {
  display: block;
  content: '';
  position: absolute;
  bottom: 3.7%;
  left: 99%;
  width: 50vw;
  height: 0.2vh;
  background-color: #000;
}


.overlays {
  position: fixed;
}

.overlays > img {
  position: absolute;
}

.overlays.horz > img {
  width: 10vw;
}
.overlays.vert > img {
  width: 10vh;
}
.overlays.horz > img.qrcode {
  width: 12vw;
}
.overlays.vert > img.qrcode {
  width: 12vh;
}


.overlays >.logo {
  top: 5vh;
  left: 5vh;
}

.overlays >.sns {
  bottom: 5vh;
  left: 5vh;
}

.overlays > .qrcode {
  right: 5vh;
  bottom: 5vh;
}
