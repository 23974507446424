.landscape.menu {
  background-image: url('../../image/bg-menu-horz.svg');
}
.portrait.menu, .portrait.menu.show-footer {
  background-image: url('../../image/bg-menu-vert.svg');
}

.menu-item {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0.2em 0;
}
.landscape .menu-item {
  font-size: 2.5em;
}
.portrait .menu-item {
  font-size: 1.5em;
}

.menu .menu-card {
  position: absolute;
}

.landscape.menu.full-height .hot1 {
  top: 31vh;
  left: 7%;
  width: 22%;
  height: 28vh;
}

.landscape.menu.full-height .hot2 {
  top: 65vh;
  left: 7%;
  width: 22%;
  height: 15.5vh;
}

.landscape.menu.full-height .cold1 {
  top: 16vh;
  left: 36.7%;
  width: 28.1%;
  height: 24vh;
}
.landscape.menu.full-height .cold2 {
  top: 46.5vh;
  left: 36.7%;
  width: 28.1%;
  height: 34vh;
}

.landscape.menu.full-height .food1 {
  top: 21.5vh;
  left: 70.5%;
  width: 23%;
  height: 24vh;
}
.landscape.menu.full-height .food2 {
  top: 52vh;
  left: 70.5%;
  width: 23%;
  height: 10vh;
}
.landscape.menu.full-height .bean {
  top: 88.5vh;
  left: 44%;
  width: 20.7%;
  height: 5vh;
}

.landscape.menu.full-width .hot1 {
  top: 31%;
  left: 6.9vw;
  width: 22.3vw;
  height: 28%;
}
.landscape.menu.full-width .hot2 {
  top: 65%;
  left: 6.9vw;
  width: 22.3vw;
  height: 25%;
}
.landscape.menu.full-width .cold1 {
  top: 16%;
  left: 36.7vw;
  width: 28.2vw;
  height: 24%;
}
.landscape.menu.full-width .cold2 {
  top: 47%;
  left: 36.7vw;
  width: 28.2vw;
  height: 33%;
}
.landscape.menu.full-width .food1 {
  top: 21%;
  left: 70.4vw;
  width: 23.3vw;
  height: 25%;
}
.landscape.menu.full-width .food2 {
  top: 52%;
  left: 70.4vw;
  width: 23.3vw;
  height: 11%;
}
.landscape.menu.full-width .bean {
  top: 88%;
  left: 44.4vw;
  width: 20.5vw;
  height: 5.7%;
}


.portrait.menu.full-height .hot1 {
  top: 21vh;
  left: 11%;
  width: 35%;
  height: 16.4vh;
}

.portrait.menu.full-height .hot2 {
  top: 41vh;
  left: 11%;
  width: 35%;
  height: 16.4vh;
}

.portrait.menu.full-height .cold1 {
  top: 11vh;
  left: 54.4%;
  width: 34.7%;
  height: 17vh;
}
.portrait.menu.full-height .cold2 {
  top: 32.5vh;
  left: 54.4%;
  width: 34.7%;
  height: 28vh;
}
.portrait.menu.full-height .food1 {
  top: 64.5vh;
  left: 10.5%;
  width: 35.8%;
  height: 15vh;
}
.portrait.menu.full-height .food2 {
  top: 71.5vh;
  left: 54.2%;
  width: 35.8%;
  height: 6vh;
}
.portrait.menu.full-height .bean {
  top: 87.5vh;
  left: 24%;
  width: 22.1%;
  height: 5vh;
}

.portrait.menu.full-width .hot1 {
  top: 21%;
  left: 10.9vw;
  width: 35.3vw;
  height: 16%;
}
.portrait.menu.full-width .hot2 {
  top: 41.4%;
  left: 10.9vw;
  width: 35.3vw;
  height: 15%;
}
.portrait.menu.full-width .cold1 {
  top: 11%;
  left: 54vw;
  width: 35.6vw;
  height: 17.3%;
}
.portrait.menu.full-width .cold2 {
  top: 32.6%;
  left: 54vw;
  width: 35.6vw;
  height: 27.6%;
}
.portrait.menu.full-width .food1 {
  top: 64.4%;
  left: 10.9vw;
  width: 35.3vw;
  height: 15%;
}
.portrait.menu.full-width .food2 {
  top: 71.5%;
  left: 54vw;
  width: 35.6vw;
  height: 6.3%;
}
.portrait.menu.full-width .bean {
  top: 87.3%;
  left: 24.4vw;
  width: 20.5vw;
  height: 5.7%;
}


.portrait.menu .bean .menu-item {
  flex-flow: column wrap;
}

.menu-item .price.has-discount {
  color: #4fae7d;
  font-weight: bold;
}
.menu-item .discount {
  position: absolute;
  top: -1em;
  right: 0;
  text-align: right;
  color: #999;
  font-size: 0.8em;
  text-decoration: line-through;
}


.landscape.menu.full-height:before {
  display: block;
  content: '';
  position: absolute;
  top: 20.49%;
  right: 99.8%;
  width: 50vw;
  height: 0.232vh;
  background-color: #000;
}

.landscape.menu.full-height:after {
  display: block;
  content: '';
  position: absolute;
  bottom: 5.85%;
  left: 99.9%;
  width: 50vw;
  height: 0.23vh;
  background-color: #000;
}


.portrait.menu.full-height:before {
  display: block;
  content: '';
  position: absolute;
  top: 14.725%;
  right: 95.5%;
  width: 50vw;
  height: 0.15vh;
  background-color: #000;
}

.portrait.menu.full-height:after {
  display: block;
  content: '';
  position: absolute;
  bottom: 1.11%;
  left: 95.65%;
  width: 50vw;
  height: 0.15vh;
  background-color: #000;
}

.portrait.menu .pink-circle {
  position: absolute;
  top: 11.6em;
  left: 2em;
  width: 13em;
  height: 13em;
  border-radius: 100%;
  background-color: #EA8180;
  opacity: 0.4;
}

.portrait.menu .blue-circle {
  position: absolute;
  top: 11.6em;
  right: -3em;
  width: 26em;
  height: 26em;
  border-radius: 100%;
  background-color: #74B4E2;
  opacity: 0.3;
}
